<template>
  <div class="login">
    <div class="mainBox">
      <!-- 头部 -->
      <el-row>
        <el-col :span="24">
          <div class="nameBox">
            <div class="cuser" @click="toCharge">户用系统平台</div>
            <div>|</div>
            <div class="cuser" @click="toCompany">中亮科技</div>
          </div>
        </el-col>
      </el-row>
      <div class="loginBox">
        <div class="loginCenterBox">
          <div class="logoBox">
            <img
              src="@/assets/images/zllogo.png"
              style="width: 60px; height: 60px"
            />
            <div class="logoName">
              <div class="topName">户用系统平台·中亮</div>
              <div class="f13 mt5">用心服务，客户至上</div>
            </div>
          </div>
          <div class="inputMainBox">
            <div class="inputBox">
              <!-- <div class="inputItem">
                         <img src="" alt=""> -->
              <el-input
                type="text"
                v-model="username"
                class="input"
                placeholder="请输入用户名称"
              >
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
              <!-- </div>
                     <div class="inputItem">
                         <img src="" alt=""> -->
              <el-input
                type="passsword"
                 show-password
                v-model="password"
                @keyup.native.enter="enterSubmit"
                class="input ml10"
                placeholder="请输入登录密码"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
              <!-- </div> -->
            </div>
            <div class="circleBox">
              <i
                class="el-icon-right marginAuto"
                v-if="!isLogin"
                @click="submit"
              ></i>
              <i class="el-icon-loading marginAuto" v-if="isLogin"></i>
            </div>
            <div
              class="illustrateBox"
              @mouseover="showBox()"
              @mouseout="hidBox()"
            >
              <img
                src="@/assets/images/attition.png"
                style="width: 15px; height: 15px"
              />
              <span class="ml5">登录说明</span>
            </div>

            <div id="hoverBox">
              <div class="hoverArrow"></div>
              <div class="hoverContent">
                <div>如需开户，请扫码联系</div>
                <img
                  src="@/assets/images/wxCode.png"
                  style="width: 80px; height: 80px; margin-top: 15px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--备案号-->
      <div class="footer">
        <div>2024 &copy; Copyright. All Rights Reserved</div>
        <div>中亮科技有限公司版权所有</div>
        <div>皖ICP备2022003155号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: false,
      username: "",
      password: "",
      code: "",
    };
  },
  methods: {
    //店教授
    toCharge(){
       window.open('https://www.dianjiaoshou.com/#/index')
    },
    //云蓝
    toCompany(){
       window.open('https://www.yunlankeji.com/')
    },
    showBox() {
      document.getElementById("hoverBox").style.display = "block";
    },
    hidBox() {
      document.getElementById("hoverBox").style.display = "none";
    },
    // 登录
    submit() {
      var that = this;
      that.isLogin = true;
      if (!that.username) {
         that.$notify.error({
          title: '错误信息提示',
          message: '请输入用户名',
          customClass: 'login-name-error'
        });
        that.isLogin = false;
        return;
      }
      if (!that.password) {
        that.$notify.error({
           title: '错误信息提示',
          message: '请输入密码',
          customClass: 'login-name-error'
        });
        that.isLogin = false;
        return;
      }
      console.log(that.username);
      console.log(that.$md5(that.password));

      //登陆到首页
      that.$http
        .post("/userInfo/loginPlatform", {
          loginName: that.username,
          password: that.$md5(that.password),
          isAdmin: 1,
        })
        .then(function (response) {

          if (response.data.code == 200) {
            console.log(response,'登录');
            that.setLocalStorage("token", response.data.code, 180 * 60 * 1000);
            // localStorage.setItem("loginName", that.username, 180 * 60 * 1000);
            // localStorage.setItem("authToken", response.data.data.token);
            // localStorage.setItem(
            //   "name",
            //   response.data.data.userInfo.name
            // );

            localStorage.setItem("loginName", that.username, 30 * 60 * 1000);


            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            localStorage.setItem("authToken", response.data.data.token);

            // that.username = "";
            // that.password = "";
            setTimeout(() => {
               that.$router.push({ path: "/index" });
               that.isLogin = false;
            },1500)
          } else {
             that.$notify.error({
          title: '错误信息提示',
          message: '用户名或密码不正确',
          customClass: 'login-name-error'
            });
            setTimeout(() => {
              that.isLogin = false;
            },2000)
          }
        });
    },
    // 回车事件
    enterSubmit() {
      this.submit();
    },

    setLocalStorage(key, value, expires) {
      // 保持到LocalStorage,expires单位是毫秒
      // expires = expires * 1000;
      let params = { key, value, expires };
      if (expires) {
        // 记录何时将值存入缓存，毫秒级
        var data = Object.assign(params, { startTime: new Date().getTime() });
        localStorage.setItem(key, JSON.stringify(data));
      } else {
        if (Object.prototype.toString.call(value) == "[object Object]") {
          value = JSON.stringify(value);
        }
        if (Object.prototype.toString.call(value) == "[object Array]") {
          value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
      }
    },
  },
  created() {},
  // 原来生成的methods
  // methods: {
//     // 登录
//     submit() {
//       var that = this;

//       if (!that.username) {
//         that.$message({
//           message: "请输入用户名",
//           type: "warning",
//         });
//         return;
//       }
//       if (!that.password) {
//         that.$message({
//           message: "请输入密码",
//           type: "warning",
//         });
//         return;
//       }
//       console.log(that.username);
//       console.log(that.$md5(that.password));

//       //登陆到首页
//       that.$http
//         .post("/userInfo/login", {
//           loginName: that.username,
//           password: that.$md5(that.password),
//         })
//         .then(function (response) {
//           if(response.data.code==200){
//             that.setLocalStorage(
//               "token",
//               response.data.code,
//               30 * 60 * 1000
//             );
//             localStorage.setItem("loginName", that.username, 30 * 60 * 1000);
//             localStorage.setItem("userInfo", JSON.stringify(response.data.data));
//             localStorage.setItem("authToken", response.data.data.token);
//             that.username = "";
//             that.password = "";
//             that.$router.push({ path: "/index" });
//           }else{
//              that.$message({
//                 message: response.data.message,
//                 type: "warning",
//               });
//           }

//         });
//     },
//     // 回车事件
//     enterSubmit() {
//       this.submit();
//     },

//     setLocalStorage(key, value, expires) {
//       // 保持到LocalStorage,expires单位是毫秒
//       // expires = expires * 1000;
//       let params = { key, value, expires };
//       if (expires) {
//         // 记录何时将值存入缓存，毫秒级
//         var data = Object.assign(params, { startTime: new Date().getTime() });
//         localStorage.setItem(key, JSON.stringify(data));
//       } else {
//         if (Object.prototype.toString.call(value) == "[object Object]") {
//           value = JSON.stringify(value);
//         }
//         if (Object.prototype.toString.call(value) == "[object Array]") {
//           value = JSON.stringify(value);
//         }
//         localStorage.setItem(key, value);
//       }
//     },
//   },
};
</script>

<style lang="less"  scoped>
body .el-notification{
  background-color: rgba(139, 88, 92,0.5);
  border: transparent;
}
.el-notification__title,.el-notification__content>p{
color: #fff;
}
h2.el-notification__title{
  font-size: 16px;
  font-weight: 400;
}
.header-left {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.header-right {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 5px 20px;
}
.main_box {
  height: calc(100vh - 70px);
  height: -webkit-calc(100vh - 70px);
  height: -moz-calc(100vh - 70px);
}

.header .text {
  padding: 0 10px;
  border-left: 1px solid #999;
}
.blue {
  color: #409eff;
}
.login {
  background: url("../assets/images/bigBg.jpg") no-repeat 100%;
  height: 100vh;
}
.mainBox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.login_form {
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  left: 50%;
  background-color: #ffffff;
  width: 50%;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding-bottom: 50px;
}
.login_form .form_tit {
  margin-top: 80px;
  color: #409eff;
  font-size: 30px;
  font-weight: bold;
}
.login_form .form_group {
  width: 80%;
  margin: 18px auto 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.login_form .form_group1 {
  width: 80%;
  margin: 18px auto 0 auto;
}
.login_form input {
  height: 42px;
  border: none;
  outline: 0;
  padding-left: 15px;
  font-size: 12px;
  box-sizing: border-box;
  outline: none;
}
.login_form input ::placeholder {
  color: #333 !important;
}
.login_btn {
  height: 45px;
  background: #409eff;
  border-radius: 5px;
  width: 65%;
  transition: all 0.3s;
  margin: 0 auto;
  margin-top: 30px;
}
.login_btn button {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: transparent;
  outline: none;
  border: none;
}
.login_btn:hover {
  transform: translateY(-6px);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
}
.footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 13px;
}
@media (max-width: 1440px) {
  .login_form .form_group {
    margin: 15px auto 0 auto;
  }
  .login_btn {
    margin-top: 20px;
  }
}
.nameBox {
  color: #fff;
  display: flex;
  margin-top: 30px;
  margin-left: 70px;
  width: 180px;
  justify-content: space-between;
}
.loginBox {
  width: 100%;
  height: 300px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  backdrop-filter: blur(2px);
}
.loginCenterBox {
  width: 800px;
  margin-left: 35vw;
}
.logoBox {
  display: flex;
  color: #fff;
  align-items: center;
  margin-top: 50px;
}
.logoName {
  margin-left: 10px;
}
.topName {
  font-size: 30px;
  font-weight: 700;
}
.mt5 {
  margin-top: 5px;
}
.inputMainBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  position: relative;
}
.inputBox {
  display: flex;
  /* margin-top: 50px; */
  align-items: center;
  /* width: 100%; */
}
.inputItem {
  width: 200px;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
}
input {
  background: none;
  border: none;
  color: #eee;
}
input::-webkit-input-placeholder {
  color: #eee;
}
input::-moz-placeholder {
  color: #eee;
}

.input /deep/ .el-input__inner {
  width: 250px !important;
  height: 45px !important;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding-left: 65px;
  color: #eee;
}
.el-input__icon {
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.circleBox {
  width: 45px;
  height: 45px;
  border: 1px solid #555;
  border-radius: 50%;
  margin-left: 10px;
  color: #eee;
  display: flex;
  align-items: center;
  font-size: 35px;
    background: rgba(255, 255, 255, 0.1);
  /* padding-left: 4px; */
}
.marginAuto {
  margin: 0 auto;
  cursor: pointer;
}
.illustrateBox {
  color: #eee;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}

.ml5 {
  margin-left: 5px;
}
#hoverBox {
  display: none;
  position: absolute;
  bottom: -150px;
  right: 0;
}
.hoverContent {
  width: 200px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 15px;
  color: #eee;
  padding: 10px;
  text-align: center;
}
.hoverArrow {
  position: absolute;
  left: 65px;
  top: -15px;
  // border-top: 10px solid #ccc;
  border-bottom: 15px solid rgba(255, 255, 255, 0.1);
  border-right: 8px solid transparent;
   border-left: 8px solid transparent;
}
.cuser{
  cursor: pointer;
}
</style>





